<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="create()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-expansion-panels>
        <v-expansion-panel
          v-for="n in news"
          :key="n._id"
        >
          <v-expansion-panel-header :class="{primary: true, 'lighten-3': n.published, 'lighten-5': !n.published}">
            <h3>{{ n.dateCreated | dateformat }} ({{ n.author | person }})</h3>
            <v-spacer />
            <v-btn
              fab
              x-small
              text
              @click.stop="pin(n._id)"
              style="max-width:36px;margin-right:16px;"
            >
              <v-icon v-if="!n.pinned">far fa-thumbtack</v-icon>
              <v-icon v-else>fa fa-thumbtack</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              text
              @click.stop="publish(n._id)"
              style="max-width:36px;margin-right:16px;"
            >
              <v-icon v-if="!n.published">far fa-eye-slash</v-icon>
              <v-icon v-else>far fa-eye</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              text
              @click.stop="open(n._id)"
              style="max-width:36px;margin-right:16px;"
            >
              <v-icon>far fa-pencil</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              text
              @click.stop="del(n._id)"
              style="max-width:36px;margin-right:16px;"
            >
              <v-icon>far fa-trash-alt</v-icon>
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-4">
            <div v-html="n.text" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <edit-news
      v-model="dialog.open"
      :id="dialog.id"
    />
  </v-container>
</template>

<script>
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

import { GRAPHQLserver } from '@/env'

const query = `
  _id published pinned dateCreated text
  author { _id givenName familyName }
  attachments { _id filename mimetype }
`

export default {
  name: 'news',

  components: {
    EditNews: () => import('../dialogs/EditNews')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    NewsFind: [],
    dialog: {
      open: false,
      id: null
    }
  }),

  computed: {
    imageBase () {
      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      return `http${base[1] === 'wss' ? 's' : ''}://${base[2]}/uploads/`
    },
    news () {
      return this.NewsFind.slice()
        .sort((a, b) => {
          if (a.pinned && !b.pinned) return -1
          if (!a.pinned && b.pinned) return 1
          return a.dateCreated > b.dateCreated ? -1 : 1
        })
        .map(n => ({
          ...n,
          text: n.text.replaceAll('<img src="/', `<img src="${this.imageBase}`)
        }))
    }
  },

  methods: {
    open (id) {
      this.dialog.id = id
      this.dialog.open = true
    },
    async del (id) {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich löschen?', 'Diese News wirklich löschen?')) {
        this.mutate({
          mutation: gql`mutation($id: UUID!) { NewsDelete(id: $id) }`,
          variables: {
            id
          }
        })
      }
    },
    publish (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $published: Boolean!) {
          NewsUpdatePublished(id: $id, published: $published) { _id }
        }`,
        variables: {
          id,
          published: !this.NewsFind.find(n => n._id === id)?.published
        }
      })
    },
    pin (id) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $pinned: Boolean!) {
          NewsUpdatePinned(id: $id, pinned: $pinned) { _id }
        }`,
        variables: {
          id,
          pinned: !this.NewsFind.find(n => n._id === id)?.pinned
        }
      })
    },
    create () {
      this.mutate({
        mutation: gql`mutation($subjectOf: UUID!) { NewsCreate(subjectOf: $subjectOf) { _id }}`,
        variables: {
          subjectOf: this.id
        }
      })
    }
  },

  apollo: {
    NewsFind: {
      query: gql`query($subjectOf: UUID!) {
        NewsFind(subjectOf: $subjectOf) { ${query} }
      }`,
      variables () {
        return {
          subjectOf: this.id
        }
      },
      subscribeToMore: {
        document: gql`
          subscription { NewsCreate { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        updateQuery: updateQuery('NewsFind', 'NewsCreate')
      }
    },
    $subscribe: {
      NewsUpdate: {
        query: gql`
          subscription { NewsUpdate { ${query} }}
        `
      },
      NewsDelete: {
        query: gql`
          subscription { NewsDelete }
        `,
        result (id) {
          deleteQuery('NewsFind', 'NewsDelete', this.NewsFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
